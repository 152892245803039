export function addComma(num: number) {
  return new Intl.NumberFormat("ja-JP").format(num);
}

export function addTax(num: number) {
  return Math.floor(num * 1.1);
}

// webstorageが使用可能かチェック
export function isActiveStorage(type: "localStorage" | "sessionStorage") {
  try {
    const storage = window[type];
    const x = "__test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    console.log("webStorageが利用できません");
    return false;
  }
}

export function isCloudflare() {
  return process.env.NITRO_PRESET?.match(/cloudflare/);
}
